<template>
    <div class="page-content header-clear-small">
			
		<div class="card card-style contact-form">
            <div class="content">


    <div id="signalersuccess" ref="signalersuccess">
        <h1 class="text-center mt-4"><i class="fa fa-2x fa-check-circle color-orange-dark-sdw"></i></h1>
        
        <p class="boxed-text-l mt-4 mb-4 text-center">
             <strong>Signalement envoyé avec succès.</strong><br> La commune où vous souhaitez faire votre signalement n'a pas encore adhéré à Ypodo, proposez à votre maire de nous contacter.
        </p>
        <div class="row mb-0 pb-4">
                    <div class="col-6">
                        <a href="/" class="btn btn-m btn-center-m button-s shadow-l rounded-s text-uppercase font-500 bg-red-dark-sdw color-white" style="width:100%">Accueil</a>
                    </div>
                    <div class="col-6">
						<a href="/signaler" class="btn btn-m btn-center-m button-s shadow-l rounded-s text-uppercase font-500 bg-green-dark-sdw color-white"  style="width:100%">Signaler</a>
                    </div>
        </div>
    </div>		
   </div>
 </div>
 
    </div>
    <!-- End of Page Content--> 
</template>
<script>

export default {
  name: 'Successout',
  data() {
    return{
	}
  },
  components: {
  },
  props: {	
  },  
  created() {
     
  },  
  mounted() {
	this.hideMap()
  },   
  methods: {
	hideMap() {
		document.getElementById('map').style.display = 'none'
	}
  }
}
</script>

